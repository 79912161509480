html {
  font-family: sans-serif;
  font-size: 14px
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  margin: 0 auto;
  width: 70vw;
}

table {
  margin: 1rem auto;
  width: 100%;
}

th {
  text-align: left;
  position: sticky;
  top: 0;
  background-color: white;
}

h1,
h2,
h3 {
  margin: 1rem auto;
}

h2 {
  margin-top: 2rem;
}

@media (prefers-color-scheme: dark) {
  html {
    background-color: #1e1e1e;
    color: whitesmoke;
  }

  th {
    background-color: #1e1e1e;
  }
}